body{
    margin: 0;
}

ul{
    list-style: none;
}

.header{
    padding: 1.2em 0.2em 0.2em;
    background: url(../img/h-bg.png) top center no-repeat;
    background-size: cover;
    box-shadow: 0 2px 5px #949494;
    address{
        text-align: right;
        color: white;
        font-style: normal;
        font-weight: bold;
        font-size: 0.9rem;
        .tel{
            font-size: 1.3rem;
        }
        @media screen and (min-width: 40em) {
            .tel{
                font-size: 1.6rem;
            }
                font-size: 1.2rem;
            }
    }
    address{

    }
}

.button-block{
    text-align: center;
    padding: 0 1.5em 5.5em;
    position: relative;
    margin: 0 1.0em;
    font-weight: bold;
    span{
        font-size: 0.9rem;
        margin-bottom: 0.4em;
        display: inline-block;
        font-weight: bold;
    }
    .button{
        margin: auto;
        text-decoration: underline;
        background-color: #bedb39;
        border-radius: 0.4em;
        box-shadow: 0 3px #004358;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
        &:hover{
            top: 3px;
            box-shadow: none;
        }
    }
}

.footer{
    margin-top: 50px;
    padding-top: 170px;
    background: url(../img/f-bg.png) top center no-repeat;
    background-size: cover;
    small{
        color: white;
        font-weight: bold;
        padding-top: 50px;
        background-color: #000;
        padding-bottom: 0.2em;
        display: block;
    }
    @media screen and (min-width: 40em) {
        background-size: auto;
    }
}

.head{
    font-family: serif;
    font-weight: bold;
    @media screen and (min-width: 40em) {
        font-size: 2.0rem
    }
}

.nav{
    padding: 1.2em 0 0.2em;
    p{
        text-align: center;
        &:nth-child(even){
            font-size: 1.5em;
            color: #4b4b4b;
        }
        a{
            display: block;
            img{
                padding: 0 0.8em;
            }
        }
        span{
            font-size: 0.8rem;
            margin-top: 0.1em;
            color: #4d4d4d;
            font-weight: bold;
        }
    }
}

.row{
    margin: 0.5em auto;
}



.light-gray{
    background-color: #f6f6f6;
}

.callout{
    border: none;
    background: none;
    margin: 1.0em auto;
    .head{

        font-size: 1.2rem;
    }
    h2{
        border-bottom: solid 5px #1f8a70;
        display: inline-block;
        margin-bottom: 0.5em;

    }
}

.news-box{
    margin: auto;
    padding: 0 1.5em;
    width: 100%;
    li{
        border-bottom: dotted 1px #4c4c4c;
        padding-bottom: 0.4em;
        margin-bottom: 0.8em;
        font-size: 0.9rem;
        &:last-of-type{
            border: none;
        }
        span{
            margin-right: 0.5em;
        }
    }
}

.caption-box{
    font-size: 0.9rem;
    padding: 0 1.5em 1.5em;
    .fa-star{
        color: #fd4600;
    }
    .fa-exclamation-circle{
        color: #004358;
    }
    .fa-circle-o{
        color: #fd4600;
    }
}

.big-car{
    padding-top: 2em;
}

.table{
    width: 100%;
    th{
        background-color: #f6f6f6;
    }
    td{
        text-align: center;
    @media screen and (min-width: 40em) {
        text-align: left;
        }
    }
}

.price-list{
    border: solid 1px #f6f6f6;
}
